












const clients = [
  {
    id: "kompetenzzentrum",
    hostnames: ["opendash.kompetenzzentrum-siegen.digital"],
    fallbackClient: false,
    openWareHost: "opendash.kompetenzzentrum-siegen.digital",
    openWareSecure: true,
    parseHost: "https://users.kompetenzzentrum-siegen.digital/parse",
    parseAppId: "1234567890",
    feedbackURL: null,
    feedbackAssigned: null,
    logoImage: require("../assets/kpz.png"),
  },
  {
    id: "kompetenzzentrum_neu",
    hostnames: ["localhost","crowdwater.fit.fraunhofer.de"],
    fallbackClient: false,
    openWareHost: "crowdwater.fit.fraunhofer.de",
    openWareSecure: true,
    parseHost: "https://crowdwater.fit.fraunhofer.de/parse",
    parseAppId: "opendashfit",
    feedbackURL: null,
    feedbackAssigned: null,
    logoImage: require("../assets/kpz.png"),
  },
  {
    id: "kuenne",
    hostnames: ["kuenne-dev.openinc.dev", "kuenne.openinc.dev"],
    fallbackClient: false,
    openWareHost: "kuenne-app.hosts.openinc.de",
    openWareSecure: true,
    parseHost: "https://kuenne-app.hosts.openinc.de/parse",
    parseAppId: "openinc",
    feedbackURL: null,
    feedbackAssigned: null,
    logoImage: require("../assets/kuenne.png"),
  },
  {
    id: "braeuer",
    hostnames: ["braeuer.openinc.dev"],
    fallbackClient: false,
    openWareHost: "openware.apps.openinc.dev",
    openWareSecure: true,
    parseHost: "https://parse-demo.apps.openinc.dev/parse",
    parseAppId: "openinc",
    feedbackURL: null,
    feedbackAssigned: null,
    logoImage: require("../assets/braeuer.png"),
  },
  {
    id: "miaas",
    hostnames: ["miaas.openinc.dev"],
    fallbackClient: false,
    openWareHost: "openware.apps.openinc.dev",
    openWareSecure: true,
    parseHost: "https://parse-demo.apps.openinc.dev/parse",
    parseAppId: "openinc",
    feedbackURL: null,
    feedbackAssigned: null,
    logoImage: require("../assets/miaas.png"),
  },
  {
    id: "demo",
    hostnames: ["localhost", "opendash.openinc.dev"],
    fallbackClient: false,
    openWareHost: "openware.apps.openinc.dev",
    openWareSecure: true,
    parseHost: "https://parse-demo.apps.openinc.dev/parse",
    parseAppId: "openinc",
    feedbackURL: "https://linear.apps.openinc.dev/api/issues?team=Test",
    feedbackAssigned: "94f2781b-eec5-4362-ba0c-c47a6822fc13", //Nico C. bekommt man durch die Website und Netzwerk untersuchen
    logoImage: null,
  },
  {
    id: "sla",
    hostnames: ["10.11.10.101"],
    fallbackClient: false,
    openWareHost: "10.11.10.101",
    openWareSecure: false,
    parseHost: "http://10.11.10.101/parse",
    parseAppId: "openinc",
    feedbackURL: null,
    feedbackAssigned: null,
    logoImage: "https://www.slawinski.de/cms/upload/layout/slawinski-logo.png",
  },
  {
    id: "localhost",
    hostnames: ["localhost", "127.0.0.1"],
    fallbackClient: false,
    openWareHost: "localhost:4567",
    openWareSecure: false,
    parseHost: "https://parse-demo.apps.openinc.dev/parse",
    parseAppId: "openinc",
    feedbackURL: null,
    feedbackAssigned: null,
    logoImage: null,
  },
  {
    id: "schniewindt",
    hostnames: ["193.101.12.189"],
    fallbackClient: false,
    openWareHost: "193.101.12.189",
    openWareSecure: false,
    parseHost: "http://193.101.12.189/parse",
    parseAppId: "openinc",
    feedbackURL: "https://linear.apps.openinc.dev/api/issues?team=SCH",
    feedbackAssigned: "94f2781b-eec5-4362-ba0c-c47a6822fc13", //Nico C. bekommt man durch die Website und Netzwerk untersuchen
    logoImage: require("../assets/schniewindt.jpg"),
  },
  {
    id: "samsmart",
    hostnames: ["samsmart.hosts.openinc.de"],
    fallbackClient: false,
    openWareHost: "samsmart.hosts.openinc.de",
    openWareSecure: true,
    parseHost: "https://samsmart.hosts.openinc.de/parse",
    parseAppId: "openinc",
    feedbackURL: null,
    feedbackAssigned: null,
    logoImage: null,
  },

  {
    id: "arens",
    hostnames: ["10.28.13.201"],
    fallbackClient: false,
    openWareHost: "10.28.13.201",
    openWareSecure: false,
    parseHost: "http://10.28.13.201/parse",
    parseAppId: "openinc",
    feedbackURL: "https://linear.apps.openinc.dev/api/issues?team=Are",
    feedbackAssigned: "94f2781b-eec5-4362-ba0c-c47a6822fc13", //Nico C. bekommt man durch die Website und Netzwerk untersuchen
    logoImage: require("../assets/arens.jpg"),
  },
];

class ClientSelector {
  

  constructor() {;ClientSelector.prototype.__init.call(this);ClientSelector.prototype.__init2.call(this);ClientSelector.prototype.__init3.call(this);ClientSelector.prototype.__init4.call(this);ClientSelector.prototype.__init5.call(this);ClientSelector.prototype.__init6.call(this);
    this.client = clients.find((client) =>
      client.hostnames.includes(window.location.hostname)
    );

    if (!this.client) {
      this.client = clients.find((client) => client.fallbackClient);
    }
  }

  getOpenWareHost() {
    return this.client.openWareHost;
  }

  getOpenWareSecure() {
    return this.client.openWareSecure;
  }

  __init() {this.getParseHost = () => {
    return this.client.parseHost;
  }}

  __init2() {this.getParseAppId = () => {
    return this.client.parseAppId;
  }}

  __init3() {this.getFeedbackURL = () => {
    return this.client.feedbackURL;
  }}

  __init4() {this.getFeedbackAssigned = () => {
    return this.client.feedbackAssigned;
  }}

  __init5() {this.getLogoImage = () => {
    return this.client.logoImage;
  }}

  __init6() {this.getClientId = () => {
    return this.client.id;
  }}
}

const clientselector = new ClientSelector();
console.log("Connect to client:", clientselector.getClientId());

export default clientselector;
